.wrapper-dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
    padding-top: 10px;
}

.extype img {
    max-width: 80px;
    /* Adjust based on your layout */
    height: auto;
    display: block;
    margin: 0 auto;
}

.container {
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
    padding: 0 15px;
    /* Add padding to prevent touching screen edges */
    box-sizing: border-box;
}